<template>
  <div class="date-picker-container">
    <TableFilter
      :filter-name="filterName"
      :filter-value="filterValue"
      :active="isDatePickerVisible"
      :clearable="clearable"
      :append-to-body="false"
      @click="openDatePicker"
      @on-clear-filter="onClearFilter"
    >
      <slot slot="icon" name="icon" />
    </TableFilter>
    <el-date-picker
      ref="datePicker"
      v-model="dateRangeState"
      v-bind="$attrs"
      :class="$direction === 'rtl' ? 'rtl' : 'ltr'"
      class="date-picker"
      :append-to-body="false"
      :picker-options="{
        ...$attrs['picker-options'],
        onPick: onDatePick,
      }"
      @change="(args) => $emit('on-date-changed', args)"
    />
  </div>
</template>

<script>
import { ref, computed } from 'vue';
import { DateTime } from 'luxon';
import { TableFilter } from '@/modules/core';

export default {
  components: { TableFilter },
  props: {
    filterName: { type: String, default: null },
    filterValue: { type: String, default: null },
    dateRange: { type: [Array, String], default: null },
    clearable: { type: Boolean, default: false },
  },
  emits: ['on-date-changed', 'on-date-pick', 'on-clear-filter'],
  setup(props, { emit }) {
    const datePicker = ref(null);
    const dateRangeState = ref(props.dateRange);
    const showedDatePicker = ref(false);

    const isDatePickerVisible = computed(() => (datePicker.value ? datePicker.value.pickerVisible : false));

    const onDatePick = ({ minDate, maxDate }) => {
      emit('on-date-pick', {
        startDate: DateTime.fromJSDate(minDate),
        endDate: maxDate ? DateTime.fromJSDate(maxDate) : null,
      });
    };

    const onClearFilter = () => {
      dateRangeState.value = null;
      emit('on-clear-filter');
    };

    const openDatePicker = () => {
      if (isDatePickerVisible.value === false && showedDatePicker.value === false) {
        datePicker.value.showPicker();
        showedDatePicker.value = true;
      } else {
        datePicker.value.hidePicker();
        showedDatePicker.value = false;
      }
    };

    return {
      datePicker,
      dateRangeState,
      isDatePickerVisible,
      onDatePick,
      onClearFilter,
      openDatePicker,
    };
  },
};
</script>

<style lang="scss" scoped>
.date-picker-container {
  display: flex;
  flex-direction: column;
  justify-content: center;
}

::v-deep {
  .el-date-range-picker {
    visibility: visible;
  }
  .el-date-picker {
    visibility: visible;
  }
  .rtl {
    .el-month-table {
      td.start-date div {
        border-top-left-radius: 0px;
        border-bottom-left-radius: 0px;
        border-top-right-radius: 24px;
        border-bottom-right-radius: 24px;
      }

      td.end-date div {
        border-top-right-radius: 0px;
        border-bottom-right-radius: 0px;
        border-top-left-radius: 24px;
        border-bottom-left-radius: 24px;
      }
    }
  }
}
.date-picker {
  visibility: hidden;
  width: 0;
  padding: 0;
  height: 0;
}
</style>
